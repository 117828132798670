import axios from '@/utils/request'

/**
 * 获取VIP列表
 * @param data 请求参数
 * @returns 返回VIP列表的响应数据
 */
export function getVipList(data) {
  return axios({
    url: '../word/api/v1/student/student-package-list',
    method: 'get',
    params: data
  })
}

/**
 * 创建支付订单
 * @param packageId 包裹ID，用于创建订单
 * @returns 返回创建订单的响应数据
 */
export function createOrder(packageId) {
  return axios({
    url: '../word/api/v1/student/create-package-order/' + packageId,
    method: 'post'
  })
}

/**
 * 获取阿里支付二维码
 * @param orderId 订单ID，用于获取支付二维码
 * @returns 返回阿里支付二维码的响应数据
 */
export function getAlipayQrcode(orderId) {
  return axios({
    url: '../word/api/v1/student/get-alipay-qrcode/' + orderId,
    method: 'get',
  })
}

/**
 * 获取会员协议详情
 * @param data 请求参数，包括校园ID和协议类型
 * @returns 返回会员协议详情的响应数据
 */
export function getVipAgreement(data) {
  return axios({
    url: `../word/api/v1/student/agreement-detail/${data.campus_id}/${data.type}`,
    method: 'get',
  })
}

/**
 * 获取微信支付二维码
 * @param orderId 订单ID，用于获取支付二维码
 * @returns 返回微信支付二维码的响应数据
 */
export function getWechatQrcode(orderId) {
  return axios({
    url: '../word/api/v1/student/get-wechat-qrcode/' + orderId,
    method: 'get',
  })
}