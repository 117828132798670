<template>
  <div class="agreement">
    <div v-html="agreement"></div>
  </div>
</template>
<script>
import { getVipAgreement } from '@/api/personal'
export default {
  data() {
    return {
      agreement: '',
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo
    },
  },
  created() {
    this.getVipAgreement()
  },
  methods: {
    getVipAgreement() {
      getVipAgreement({
        campus_id: this.userInfo.campus_id,
        type: 1,
      }).then((res) => {
        if (res.code == 200) {
          this.agreement = res.data && res.data.content
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.agreement {
  width: 1000px;
  margin: auto;
  padding: 20px 0;
}
</style>